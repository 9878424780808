<template>
	<Layout>
		<Header>
			<b-button v-if="permissionEnabled('ratings', 'read')" type="is-secondary export" :loading="exporting" size="is-small" @click="exportRanking()" rounded outlined>
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>Exportar</span>
			</b-button>
		</Header>
		<section class="filter">
			<div class="filter__wrapper">
				<b-field label="Avaliação" label-position="on-border">
					<b-select class="ratings" :placeholder="rating ? rating.name : ''" @input="findDealers">
						<option v-for="r in ratings" :key="r.id" class="is-capitalized" :value="r.id" :selected="ratingId == r.id">{{ r.name }}</option>
					</b-select>
				</b-field>
			</div>
		</section>
		<Error v-if="errored" :icon="true" :back="true" />
		<Results v-if="!loading && !errored && dealers.length <= 0" message="Ainda não existe<br><strong>nenhuma</strong> avaliação concluída." />
		<div v-if="loading" class="columns is-multiline">
			<div v-for="n in pagination" :key="n" class="column is-12-mobile is-6-tablet is-4-desktop">
				<Placeholder />
			</div>
		</div>
		<transition-group name="filtering" class="filtering columns is-multiline" tag="div">
			<div v-for="(d, i) in dealers" :key="d.id" class="column is-12-mobile is-6-tablet is-4-desktop">
				<article class="block ranking">
					<div class="block__content">
						<h3 class="block__name">
							<span class="position">{{ i + 1 }}º</span> 
							<span>{{ d.name }}</span>
						</h3>
						<!-- <svg-icon v-if="i <= 2" icon="medal" :class="`medal-${i + 1}`"></svg-icon> -->
						<b-tooltip :label="colorLabel(d.rate)" position="is-top">
							<svg-icon icon="star" :class="color(d.rate)"></svg-icon>
							<span class="rate" :class="color(d.rate)">{{ d.rate }}</span>
						</b-tooltip>
					</div>
				</article>
			</div>
		</transition-group>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Placeholder from '@/components/placeholders/Ranking'
import Error from '@/components/Error'
import Results from '@/components/Results'
import Api from '@/services/api'
import { mapGetters } from 'vuex'
import { errorToast, successToast } from '@/mixins/toast'
import '@/mixins/generic'

export default {
	components: {
		Layout,
		Header,
		Placeholder,
		Error,
		Results,
		'svg-icon': Icon
	},
	data() {
		return {
			exporting: false,
			errored: false,
			loading: true,
			pagination: 15,
			ratingId: this.$route.params.id,
			rating: null,
			ratings: [],
			dealers: []
		}
	},
	methods: {
		async findRatings() {
			Api.get('rating/findAll')
				.then(({ data }) => {
					this.ratings = data
					if (this.ratings.length > 0) {
						if (this.ratingId) {
							this.findDealers(this.ratingId)
						} else {
							this.findDealers(this.ratings[0].id)
						}
					}
				})
				.catch((err) => {
					console.log(err)
					this.errored = true
					errorToast('Ocorreu um erro ao buscar as avaliações')
				})
		},
		async findDealers(id) {
			this.rating = this.ratings.find((r) => r.id == id)

			try {
				this.loading = true
				const response = await Api.get(`dashboard${id ? `/${id}` : ''}`)
				const { status, data } = response

				if (status === 200) {
					let r = data.data[0]
					this.loading = false
					this.dealers = r.dealers.slice().sort(function(a, b) {
						return b.rate - a.rate
					})
				}
			} catch (e) {
				this.loading = false
				console.log(e)
			}
		},
		async exportRanking() {
			this.exporting = true
			try {
				const response = await Api.post('rating/export-ranking', { ratingId: this.rating.id }, { responseType: 'blob' })
				if (response.status !== 200) {
					errorToast('Ocorreu um <strong>erro</strong> ao exportar o ranking.')
				} else {
					let blob = new Blob([response.data], { type: response.data.type })
					let link = document.createElement('a')
					link.href = window.URL.createObjectURL(blob)
					link.download = 'ranking.xls'
					link.click()

					successToast('O ranking dos dealers foi exportados com <strong>sucesso</strong>.')
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.exporting = false
			}
		}
	},
	mounted() {
		this.findRatings()
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
